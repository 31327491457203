<template>
  <div id="verifylogin">
    <div id="pwdlogin">
      <div class="wrap">
        <logins @loginSubmit="loginSubmit">
          <div slot="center">
            <div class="img" v-if="roleNumber == 2">
              <div class="title co_333">请选择您的登录角色</div>
              <div class="register">
                <div class="supplier" @click="roleLogin(1)">
                  <img class="img" src="@/assets/Login/sbg.png" />
                  <div class="role co_fff">
                    <img class="icon" src="@/assets/Login/supplier.png" />
                    <span>供应商</span>
                  </div>
                </div>
                <div class="purchaser" @click="roleLogin(2)">
                  <img class="img" src="@/assets/Login/pbg.png" />
                  <div class="role co_fff">
                    <img class="icon" src="@/assets/Login/gouwuman.png" />
                    <span>采购商</span>
                  </div>
                </div>
              </div>
            </div>
            <verify-center
              v-else
              ref="verifyCenter"
              @validateSuccess="loginByCode"
            ></verify-center>
          </div>
          <router-link slot="icon1" :to="{ name: 'UserLogin' }">
            <img src="@/assets/Login/pwd.png" />
          </router-link>
          <router-link slot="icon2" :to="{ name: 'WechatLogin' }">
            <img src="@/assets/Login/weChat.png" />
          </router-link>
        </logins>
      </div>
    </div>
  </div>
</template>
<script>
import Logins from "@/components/login/logins.vue";
import VerifyCenter from "@/components/login/verifyCenter";
import { checkMobileInSys } from "@/api/user";
export default {
  components: {
    Logins,
    VerifyCenter,
  },
  name: "VerifyLogin",
  data() {
    return {
      submitData: {},
      roleNumber: 0,
    };
  },
  methods: {
    // 手机验证码登录
    loginByCode(data) {
      this.submitData = data;
      this.checkMobileInSys(data.mobile).then((boo) => {
        if (boo) {
          this.login(data);
        } else {
          // 提示系统内不存在该用户，转去注册
          this.$message({
            type: "warning",
            message: "系统内不存在该用户，请先注册！",
          });
          // setTimeout(() => {
          //   this.$router.push({
          //     name: "RoleChoose",
          //   });
          // }, 3000);
        }
      });
    },
    // 校验手机是否有账号
    checkMobileInSys(mobile) {
      return new Promise((resolve) => {
        this.roleNumber = 0;
        checkMobileInSys(mobile).then((res) => {
          if (res.success) {
            this.roleNumber = res.data;
            if (this.roleNumber == 2) {
              return false;
            }
            resolve(true);
          } else {
            this.roleNumber = 0;
            resolve(false);
          }
        });
      });
    },
    loginSubmit() {
      this.$refs.verifyCenter.submitForm("verifyloginForm");
    },
    // 角色登录
    roleLogin(type) {
      let data = this.deepClone(this.submitData);
      data.roleType = type;
      this.login(data);
    },
    // 登录
    login(data) {
      this.$store.dispatch("loginByMobileCode", data).then((res) => {
        if (res.success) {
          this.$message.success("登陆成功！");
          let redirect = this.$route.query.redirect;
          if (redirect) {
            let query = this.$route.query.query;
            query = JSON.parse(query);
            this.$router.push({
              name: redirect,
              query: query,
            });
          } else {
            this.$router.push({
              path: "/",
            });
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
#verifylogin {
  height: 100%;
  background: url("../../assets/Login/pbig.png") no-repeat center center;
  background-size: cover;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.register {
  .purchaser,
  .supplier {
    display: inline-block;
    position: relative;
    cursor: pointer;
    .img {
      width: 175px;
    }
    .role {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      /* text-align: center; */
      .icon {
        width: 40px;
        display: inline-block;
      }
      span {
        font-size: 14px;
        display: block;
      }
    }
  }
  .purchaser {
    margin-left: 20px;
  }
}
.title {
  margin-bottom: 30px;
}
</style>